<template>
  <v-card
    class="px-md-12 animate__animated animate__slideInDown fill-height"
    color="background"
    flat
    tile
    loader-height="100"
    :loading="loader"
  >
    <v-row no-gutters align="start" justify="center" v-if="!loader">
      <v-col sm="12" cols="12">
        <v-card flat color="background">
          <v-card-actions class="pt-4 pr-4">
            <!-- <v-spacer />
            <v-btn
              id="btn-darktoogle"
              fab
              small
              @click="setDark"
              depressed
              color="primary"
              elevation="2"
            >
              <v-icon>{{
                dark ? "mdi-invert-colors" : "mdi-invert-colors-off"
              }}</v-icon>
            </v-btn> -->
          </v-card-actions>
          <v-card-title class="py-12">
            <v-spacer />
            <v-img
              src="../../../assets/img/logo-falabellacorp.png"
              contain
              max-height="150"
              max-width="250"
            />
            <v-spacer />
          </v-card-title>
          <v-card-text
            justify="center"
            align="center"
            class="py-6 display-1 font-weight-thin "
          >
            Iniciar sesión
          </v-card-text>
        </v-card>
        <v-form class="px-8" lazy-validation v-model="valid" ref="form">
          <v-text-field
            id="input-email"
            v-model="email"
            prepend-inner-icon="mdi-email"
            color="primary"
            placeholder="castorga@falabella.cl"
            label="Correo electrónico"
            :rules="[
              (v) => !!v || 'Ingrese su correo electrónico',
              (v) =>
                emailRegex.test(v) || 'Ingrese un correo electrónico válido',
            ]"
            outlined
            @keyup.enter="submitForm()"
            type="text"
          />
          <v-text-field
            id="input-password"
            v-model="password"
            prepend-inner-icon="mdi-key"
            :append-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (hidePassword = !hidePassword)"
            :type="hidePassword ? 'password' : 'text'"
            color="primary"
            label="Contraseña"
            placeholder="*********"
            :rules="[(v) => !!v || 'Ingrese su contraseña']"
            outlined
            @keyup.enter="submitForm()"
          />
        </v-form>
        <v-card-actions class="pa-2 px-8 justify-center">
          <v-btn
            id="btn-auth"
            color="primary"
            dark
            block
            large
            v-if="!loader"
            @click="submitForm()"
          >
            Autenticar
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-row class="py-12 my-12" align="center" justify="center" v-else>
      <v-card flat color="transparent" class="py-12 my-12">
        <v-card-title class="justify-center pt-12 mt-12">
          <v-progress-circular
            indeterminate
            button
            color="primary"
            size="80"
            width="10"
            class="animated bounceIn"
          />
        </v-card-title>
        <v-card-text
          class="tracking-in-expand-fwd-bottom body-1 mt-3 justify-center"
        >
          Validando las credenciales...
        </v-card-text>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
/**
 * Login form component
 * @author Cristian Molina
 */
export default {
  name: "LoginForm",
  data: () => ({
    hidePassword: true,
    dark: false,
    help: false,
    valid: false,
    email: null,
    password: null,
    errors: [],
    loader: false,
    emailRegex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  }),
  methods: {
    /**
     * @description Check form and validate
     * @author Cristian Molina
     */
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.accesslogin(this.email, this.password);
      }
    },
    /**
     * @description Login function
     * @param {any} user
     * @param {any} pass
     * @author Cristian Molina
     */
    accesslogin(user, pass) {
      this.loader = true;
      setTimeout(() => {
        this.loader = false;
        if (user === "custretu@falabella.cl" && pass === "password") {
          this.$router.push("/dash");
        } else {
          this.$notification.error("Usuario y/o Contraseña invalidos");
          this.valid = false;
          this.email = "";
          this.password = "";
        }
      }, 2000);
    },
  },
};
</script>

<style scoped>
.gradient {
  background-image: linear-gradient(to right, #cf7244, #415793);
}
</style>
